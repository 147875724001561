import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { TRootState } from '../../store';
import PersonsApi from '../../API/PersonsApi';
import { fetch, success, fail } from '../slices/persons';

function fetchPersons(size: number = 0): ThunkAction<void, TRootState, unknown, AnyAction> {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const result = await PersonsApi.fetchRandom(size);

      if (result.status === 200) {
        dispatch(success(result.data));
      } else {
        dispatch(fail('Ошибка загрузки списка персон'));
      }
    } catch (error) {
      dispatch(fail('Ошибка загрузки списка персон'));
    }
  };
}

export default fetchPersons;
