import { Link } from 'react-router-dom';
import { Button } from '../../../../Components';
import siberianSrc from '../../../../images/Сибирская.svg';
import styles from './Header.module.css';

function MainBlock() {
  return (
    <div className={styles.container}>
      <div className={styles['main-text-container']}>
        <img src={siberianSrc} alt="Сибирская" className={styles.image} />
        <p className={styles['main-text']}>
          Академическая наука в лицах
        </p>
      </div>
      <p className={styles['info-text']}>
        Комплексный информационный ресурс
        <br />(к 300-летию РАН)
      </p>
      <Link to="/alphabetical-index" className={styles.link}>
        <Button className={styles.button}>Алфавитный указатель</Button>
      </Link>
    </div>
  );
}

export default MainBlock;
