import { memo } from 'react';
import Icon from '../Icon/Icon';
import styles from './Checkbox.module.css';

interface ICheckboxProps {
  className?: string;
  value: boolean;
  onClick: (name: string, value: boolean) => void;
  label: string;
  name: string;
}

function Checkbox(props: ICheckboxProps) {
  const { className, value, onClick, label, name } = props;

  return (
    <div className={`${styles.container} ${className}`} onClick={() => onClick(name, !value)}>
      <div className={`${styles.checkbox} ${value && styles.active}`}>
        <Icon type="check" />
      </div>
      <span className={styles.label}>{label}</span>
    </div>
  );
}

export default memo(Checkbox);
