import styles from './Loader.module.css';

interface ILoaderProps {
  className?: string;
}

function Loader(props: ILoaderProps) {
  const { className } = props;

  return (
    <div className={`${styles.spinner} ${className}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default Loader;
