import { memo, KeyboardEvent } from 'react';
import { Icon } from '../../../../Components';
import styles from './Search.module.css';

interface ISearchNameProps {
  value: string;
  setSearch: (value: string) => void;
  onSearch: () => void;
  reset?: () => void;
}

function Search(props: ISearchNameProps) {
  const { value, setSearch, onSearch, reset } = props;

  const pressEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') onSearch();
  }

  const search = () => {
    if (value.length) {
      onSearch();
    }
  };

  return (
    <>
      <h2 className={styles.title}>Поиск по фио</h2>
      <div className={styles['input-container']}>
        <input
          name="search"
          value={value}
          onChange={event => setSearch(event.currentTarget.value)}
          className={styles.input}
          placeholder="Введите имя"
          onKeyDown={pressEnter}
        />
        {
          value !== '' && reset &&
          <button className={styles['close-button']} onClick={reset}>
            <Icon type="close" />
          </button>
        }
        <button className={styles['loupe-button']} onClick={search}>
          <Icon type="loupe" className={styles.icon} />
        </button>
      </div>
    </>
  );
}

export default memo(Search);
