import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TRootState } from "../../store";
import { TRegalia } from '../../types/regalia';

interface IRegaliaState {
  list: TRegalia[] | null;
  loading: boolean;
  error: string;
}

const initialState: IRegaliaState = {
  list: null,
  loading: false,
  error: '',
};

export const regaliasSlice = createSlice({
  name: 'regalias',
  initialState,
  reducers: {
    fetch: (state) => {
      state.error = '';
      state.loading = true;
    },
    success: (state, action: PayloadAction<TRegalia[]>) => {
      state.loading = false;
      state.list = action.payload;
    },
    fail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetch, success, fail } = regaliasSlice.actions;

export const regaliasSelector = (state: TRootState) => state.regalias;

export default regaliasSlice.reducer;
