import { useEffect } from 'react';
import useScroll from '../../services/hooks/useScroll';

import { Container } from '../../Components';
import { Header, Persons, Resources } from './Components';

function HomePage() {
  useEffect(() => {
    document.title = "Лица Сибирской науки";
  }, []);

  useScroll();

  return (
    <Container>
      <Header />
      <Persons />
      <Resources />
    </Container>
  );
}

export default HomePage;
