import { BrowserRouter } from 'react-router-dom';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Router from '../Router/Router';

function App() {
  return (
    <BrowserRouter>
      <Header />
      <div className='main_contetnt'>
        <Router />
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
