import { memo } from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { TPerson } from '../../../../types/persons';

import { Dropdown, Icon, Loader } from '../../../../Components';
import styles from './List.module.css';
import RegaliaListOut from "../../../Home/Components/RegaliaListOut/RegaliaListOut";

interface IListProps {
  list: TPerson[] | null;
  loading: boolean;
  error: string;
  personsLength: number;
  quantity: string;
  setQuantity: (value: string) => void;
  page: string;
  pageChange: (selectedItem: { selected: number; }) => void;
}

const quantityData = ['5', '10', '15', '20'];

function List(props: IListProps) {
  const {
    personsLength, quantity,
    setQuantity, page, pageChange, loading,
    error, list,
  } = props;

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        {/* <div className={`${styles['dropdown-container']} ${styles['dropdown-container-sort']}`}>
          <span className={styles['dropdown-container-sort-label']}>Сортировать</span>
          <Dropdown
            className={styles.dropdown}
            data={sortData}
            value={sort}
            onChange={setSort}
          />
        </div> */}
        <div className={styles['dropdown-container']}>
          <span>Отобразить</span>
          <Dropdown
            className={styles.dropdown}
            data={quantityData}
            value={quantity}
            onChange={setQuantity}
          />
        </div>
        {
          personsLength > +quantity &&
          <div className={styles['top-pagination']}>
            <ReactPaginate
              pageCount={Math.ceil(personsLength / +quantity)}
              onPageChange={pageChange}
              containerClassName={styles['pagination-container']}
              pageClassName={styles['pagination-page']}
              activeClassName={styles['pagination-active']}
              previousLabel={<Icon type="little-arrow" className={`${styles['pagination-button']}`} />}
              nextLabel={<Icon type="little-arrow" className={`${styles['pagination-button']} ${styles['pagination-next']}`} />}
              forcePage={+page}
            />
          </div>
        }
      </div>
      <div className={styles['list-container']}>
        {
          loading &&
          <Loader className={styles.loader} />
        }
        {
          error &&
          <p className={styles['error-message']}>
            {error}
          </p>
        }
        {
          (list && list.length > 0) && list.map(
            (item, i) =>
              <Link to={`/person/${item._id}`} key={i} className={styles['card-link']} state={{ back: true }}>
                <article className={styles.card}>
                  <div className={styles['card-image-container']}>
                    <img
                      src={item.imgHref ? `${process.env.REACT_APP_PHOTO_URL}/files/person/${item._id}/${item.imgHref}` : '/images/no-image.png'}
                      alt={`${item.name.last} ${item.name.first} ${item.name.middle}`}
                    />
                  </div>
                  <div className={styles['inner-container']}>
                    <h1 className={styles['card-title']}>
                      {`${item.name.last} ${item.name.first} ${item.name.middle}`}
                    </h1>

                     <RegaliaListOut person={item}></RegaliaListOut>

                  </div>
                </article>
              </Link>
          )
        }
        {
          (list && !list.length) &&
          <p className={styles['error-message']}>
            Нет результатов по вашему запросу
          </p>
        }
      </div>
      {
        personsLength > +quantity &&
        <div className={styles.footer}>
          <ReactPaginate
            pageCount={Math.ceil(personsLength / +quantity)}
            onPageChange={pageChange}
            containerClassName={styles['pagination-container']}
            pageClassName={styles['pagination-page']}
            activeClassName={styles['pagination-active']}
            previousLabel={<Icon type="little-arrow" className={`${styles['pagination-button']}`} />}
            nextLabel={<Icon type="little-arrow" className={`${styles['pagination-button']} ${styles['pagination-next']}`} />}
            forcePage={+page}
          />
        </div>
      }
    </section>
  );
}

export default memo(List);
