import qs from 'qs';
import axios from '../axios';

export interface IPersonsFilters {
  name?: string;
  letter?: string;
  yearsStatus?: string;
  year?: string;
  status?: string[];
}

class PersonsApi {
  static async fetch(skip: number, limit: number, filters: IPersonsFilters = {}) {
    return await axios.get('/api/person/list', {
      params: {
        skip, limit, ...filters,
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    });
  }

  static async fetchRandom(size:number) {
    return await axios.get('/api/person/listRandom', {
      params: { size },
      paramsSerializer: function(params) {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      },
    });
  }

  static async fetchLetters() {
    return await axios.get('/api/person/listLetters', {
      params: {},
      paramsSerializer: function(params) {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      },
    });
  }

  static async fetchLength(filters: IPersonsFilters = {}) {
    return await axios.get('/api/person/listSize', {
      params: {
        ...filters,
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    });
  }

  static async fetchPerson(id: string) {
    return await axios.get(`/api/person/${id}`);
  }
}

export default PersonsApi;
