import { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useMatch, useLocation, useSearchParams } from 'react-router-dom';

import Container from '../Container/Container';
import Icon from '../Icon/Icon';
import logoSrc from '../../images/logo.svg';
import navIconSrc from '../../images/nav-icon.svg';
import styles from './Header.module.css';
import Nav from '../Nav/Nav';

function Header() {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const locationPage = location.state?.page;
  const [isOpen, setOpen] = useState(false);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const homeActive = useMatch('/');
  const alphabeticalActive = useMatch('alphabetical-index');
  const aboutActive = useMatch('about');

  const memberStatus = searchParams.get('ys');

  const handleClick = () => {
    setOpen(isOpen => !isOpen);
  };

  const handleNavClick = useCallback(() => {
    setOpen(false);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    document.onscroll = function (event) {
      if (headerRef.current && buttonRef.current) {
        const y = headerRef.current.getBoundingClientRect().y;

        if (y < -200) {
          buttonRef.current.classList.remove(styles.hidden);
        } else {
          buttonRef.current.classList.add(styles.hidden);
        }
      }
    }
  }, []);

  return (
    <>
      <header className={styles.header} ref={headerRef}>
        <Container className={styles.container}>
          <Link to="/" className={styles['logo-link']} state={locationPage && { page: locationPage }}>
            <div className={styles['logo-container']}>
              <img src={logoSrc} alt="Лица Сибирской науки" className={styles.logo} />
              <span>Лица<br /> Сибирской<br /> науки</span>
            </div>
          </Link>
          <nav className={styles['nav-container']}>
            <Link to="/" className={`${homeActive && styles.active}`}>
              Главная
            </Link>
            <Link to="/alphabetical-index" className={`${(alphabeticalActive && memberStatus === null) && styles.active}`} state={locationPage && { page: locationPage }} reloadDocument>
              Алфавитный указатель
            </Link>
            <Link to="/alphabetical-index?ys=Действительный+член" className={`${(alphabeticalActive && memberStatus === 'Действительный член') && styles.active}`} state={locationPage && { page: locationPage }} reloadDocument>
              Действительные члены
            </Link>
            <Link to="/alphabetical-index?ys=Член-корреспондент" className={`${(alphabeticalActive && memberStatus === 'Член-корреспондент') && styles.active}`} state={locationPage && { page: locationPage }} reloadDocument>
              Члены-корреспонденты
            </Link>
            <Link to="/about" className={`${aboutActive && styles.active}`}>
              О проекте
            </Link>
          </nav>
          <button
            onClick={handleClick}
            className={`${styles.button} ${isOpen && styles['active-button']}`}
          >
            <img src={navIconSrc} alt="" />
          </button>
        </Container>
        <button
          ref={buttonRef}
          className={`${styles['arrow-button']} ${styles.hidden}`}
          onClick={scrollToTop}
        >
          <Icon type="arrow" />
        </button>
      </header>
      {
        isOpen && <Nav onClick={handleNavClick} />
      }
    </>
  );
}

export default Header;
