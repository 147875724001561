import styles from './RadioButtons.module.css';

interface IRadioButtonsProps {
  className?: string;
  children: JSX.Element[];
}

interface IRadioButtonProps {
  className?: string;
  label: string;
  active: boolean;
  onClick: (value: string) => void;
}

function RadioButtons(props: IRadioButtonsProps) {
  const { className, children } = props;

  return (
    <div className={`${styles.container} ${className}`}>
      {children}
    </div>
  );
}

RadioButtons.Button = (props: IRadioButtonProps) => {
  const { className, label, active, onClick } = props;

  return (
    <div
      className={`${styles['button-container']} ${className}`}
      onClick={() => onClick(label)}
    >
      <div className={`${styles.button} ${active && styles.active}`}>
        <div />
      </div>
      <span className={styles.label}>{label}</span>
    </div>
  );
};

export default RadioButtons;