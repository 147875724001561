import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { TRootState } from '../../store';
import PersonsApi, { IPersonsFilters } from '../../API/PersonsApi';
import { setPersonsLength } from '../slices/persons';

function fetchPersonsLength(filters?: IPersonsFilters): ThunkAction<void, TRootState, unknown, AnyAction> {
  return async (dispatch) => {
    try {
      const result = await PersonsApi.fetchLength(filters);

      if (result.status === 200) {
        dispatch(setPersonsLength(result.data[0].n));
      } else {
        dispatch(setPersonsLength(0));
      }
    } catch(error) {
      dispatch(setPersonsLength(0));
    }
  };
}

export default fetchPersonsLength;
