import React from 'react';

type IUseToggleResult = [
  boolean,
  (value?: boolean) => void,
];

function useToggle(value: boolean): IUseToggleResult {
  const [toggler, setToggler] = React.useState(value);

  const toggle = React.useCallback((value?: boolean) => {
    if (value) {
      setToggler(value);
    } else {
      setToggler(value => !value);
    }
  }, []);

  return [
    toggler,
    toggle,
  ];
}

export default useToggle;
