import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TRootState } from "../../store";
import { TLiteratue } from '../../types/literature';

interface IPersonsState {
  list: TLiteratue[] | null;
  loading: boolean;
  error: string;
}

const initialState: IPersonsState = {
  list: null,
  loading: false,
  error: '',
};

export const literatureSlice = createSlice({
  name: 'literature',
  initialState,
  reducers: {
    fetch: (state) => {
      state.error = '';
      state.loading = true;
    },
    success: (state, action: PayloadAction<TLiteratue[]>) => {
      state.loading = false;
      state.list = action.payload;
    },
    fail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetch, success, fail } = literatureSlice.actions;

export const literatureSelector = (state: TRootState) => state.literature;

export default literatureSlice.reducer;
