import { configureStore } from "@reduxjs/toolkit";
import facetsReducer from "../services/slices/facets";
import personsReducer from "../services/slices/persons";
import personReducer from "../services/slices/person";
import literatureReducer from "../services/slices/literature";
import regaliasReducer from "../services/slices/regalias";
import personsLettersReducer from "../services/slices/personsLetters";

const store = configureStore({
  reducer: {
    facets: facetsReducer,
    persons: personsReducer,
    person: personReducer,
    literature: literatureReducer,
    regalias: regaliasReducer,
    letters: personsLettersReducer,
  },
});

export type TRootState = ReturnType<typeof store.getState>;

export type TAppDispatch = typeof store.dispatch;

export default store;
