import useToggle from '../../services/hooks/useToggle';

import Icon from '../Icon/Icon';
import styles from './Dropdown.module.css';

interface IDropdownProps {
  className?: string;
  data: string[];
  value: string;
  onChange: (value: string) => void;
}

function Dropdown(props: IDropdownProps) {
  const { className, value, data, onChange } = props;

  const [isOpen, setOpen] = useToggle(false);

  const handleClick = () => {
    setOpen();
  };

  return (
    <div className={`${styles.container} ${className}`} onClick={() => handleClick()}>
      <span className={styles.current}>
        {value}
      </span>
      <button className={`${styles.button} ${isOpen && styles.close}`}>
        <Icon type="small-arrow" />
      </button>
      {
        isOpen &&
          <div className={styles.dropdown}>
            {
              data.map(
                item =>
                  <button
                    key={item}
                    className={styles['dropdown-button']}
                    onClick={() => onChange(item)}
                  >
                    {item}
                  </button>
              )
            }
          </div>
      }
    </div>
  );
}

export default Dropdown;
