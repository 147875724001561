import arrowSrc from '../../images/arrow.svg';
import ranSrc from '../../images/ran.svg';
import gpntbSrc from '../../images/gpntb.svg';
import loupeSrc from '../../images/loupe.svg';
import closeSrc from '../../images/close.svg';
import checkSrc from '../../images/check.svg';
import littleArrowSrc from '../../images/little-arrow.svg';
import smallArrowSrc from '../../images/small-arrow.svg';
import squareSrc from '../../images/square.svg';
import grayCloseSrc from '../../images/gray-close.svg';

interface IIconProps {
  className?: string;
  type: 'arrow' | 'ran' | 'gpntb' | 'loupe' | 'close' | 'check' | 'little-arrow' | 'small-arrow' | 'square' | 'gray-close';
  alt?: string;
}

function Icon(props: IIconProps) {
  const { type, alt, className } = props;
  let icon: string;

  switch (type) {
    case 'arrow':
      icon = arrowSrc; break;
    case 'ran':
      icon = ranSrc; break;
    case 'gpntb':
      icon = gpntbSrc; break;
    case 'loupe':
      icon = loupeSrc; break;
    case 'close':
      icon = closeSrc; break;
    case 'check':
      icon = checkSrc; break;
    case 'little-arrow':
      icon = littleArrowSrc; break;
    case 'small-arrow':
      icon = smallArrowSrc; break;
    case 'square':
      icon = squareSrc; break;
    case 'gray-close':
      icon = grayCloseSrc; break;
  }

  return (
    <img
      src={icon}
      alt={alt || ''}
      className={className}
    />
  );
}

export default Icon;
