import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { TRootState } from '../../store';
import FacetsApi from '../../API/FacetsApi';
import { fetch, success, fail } from '../slices/facets';

function fetchFacets(): ThunkAction<void, TRootState, unknown, AnyAction> {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const result = await FacetsApi.fetchFacets();

      if (result.status === 200) {
        dispatch(success(result.data));
      } else {
        dispatch(fail('Ошибка загрузки данных'));
      }
    } catch(error) {
      dispatch(fail('Ошибка загрузки данных'));
    }
  };
}

export default fetchFacets;
