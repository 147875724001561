import { useState, useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../services/hooks/useStore';
import { personsSelector } from '../../services/slices/persons';
import { facetSelector } from '../../services/slices/facets';
import useScroll from '../../services/hooks/useScroll';
import usePrev from '../../services/hooks/usePrev';
import fetchPersonsThunk from '../../services/thunks/fetchPersons';
import fetchPersonsLengthThunk from '../../services/thunks/fetchPersonsLength';
import fetchFacetsThunk from '../../services/thunks/fetchFacets';

import { Container } from '../../Components';
import { Facet, Alphabet, List } from './Components';
import styles from './AlphabeticalIndex.module.css';

function AlphabeticalIndexPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  useScroll();

  // STATE
  const [letter, setLetter] = useState<string>(searchParams.get('letter') || '');
  const [quantity, setQuantity] = useState<string>(localStorage.getItem('table-quantity') || '5');
  const [page, setPage] = useState<string>(searchParams.get('page') || '0');
  const [search, setSearch] = useState(searchParams.get('search') || '');
  const [status, setStatus] = useState<{ [key: string]: boolean }>({});
  const [yearsStatus, setYearsStatus] = useState(searchParams.get('ys') || 'Все');
  const [year, setYear] = useState('');

  // PREV VALUES
  const prevPage = usePrev(page);
  const prevLetter = usePrev(letter);

  // STORE
  const dispatch = useAppDispatch();
  const persons = useAppSelector(personsSelector);
  const facets = useAppSelector(facetSelector);

  // HANDLERS
  const setLetterHandler = useCallback((value: string) => {
    if (letter === value) {
      setLetter('');
      searchParams.delete('letter');
    } else {
      setLetter(value);
      searchParams.set('letter', value);
    }

    setSearchParams(searchParams);
  }, [letter, searchParams, setSearchParams]);

  const setQuanityHandler = useCallback((value: string) => {
    setQuantity(value);
    localStorage.setItem('table-quantity', value);
  }, []);

  const pageChangeHandler = useCallback((selectedItem: { selected: number; }) => {
    setPage(`${selectedItem.selected}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (selectedItem.selected === 0) {
      searchParams.delete('page');
    } else {
      searchParams.set('page', `${selectedItem.selected}`);
    }

    setSearchParams(searchParams);
  }, [searchParams, setSearchParams, page]); // eslint-disable-line

  const searchHandler = useCallback(() => {   // (Press Enter)
    if (search === '') {
      searchParams.delete('search');
    } else {
      searchParams.set('search', search);
    }
    setSearchParams(searchParams);

    const statuses = searchParams.getAll('status');
    const yearsStatus = searchParams.get('ys');
    const year = searchParams.get('year');

    const filters = {
      ...(search && { personName: search }),
      ...(letter && { letter }),
      ...(statuses.length && { status: statuses }),
      ...(yearsStatus && { yearsStatus }),
      ...(year && { year }),
    };

    dispatch(fetchPersonsThunk(+quantity * +page, +quantity, filters));
    dispatch(fetchPersonsLengthThunk(filters));

    setPage('0');
    searchParams.delete('page');
    setSearchParams(searchParams);
  }, [search, letter, quantity, page, searchParams, setSearchParams, dispatch]);

  const resetSearch = useCallback(() => {
    setSearch('');
    searchParams.delete('search');
    setSearchParams(searchParams);

    const statuses = searchParams.getAll('status');
    const yearsStatus = searchParams.get('ys');
    const year = searchParams.get('year');

    const filters = {
      ...(search && { personName: '' }),
      ...(letter && { letter }),
      ...(statuses.length && { status: statuses }),
      ...(yearsStatus && yearsStatus !== "Все" && { yearsStatus }),
      ...(year && { year }),
    };

    dispatch(fetchPersonsThunk(+quantity * +page, +quantity, filters));
    dispatch(fetchPersonsLengthThunk(filters));

    setPage('0');
    searchParams.delete('page');
    setSearchParams(searchParams);

  }, [page, prevPage, searchParams, setSearchParams, dispatch, letter, quantity, search]); // eslint-disable-line

  const facetsHandleClick = useCallback((name: string, value: boolean) => {
    setStatus((state) => ({ ...state, [name]: value }));
  }, []);

  const find = useCallback((statuses: string[]) => {
    setPage('0');
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (search) {
      searchParams.delete('search');
      searchParams.set('search', search);
    }

    if (statuses.length) {
      searchParams.delete('status');
      statuses.forEach(item => {
        searchParams.append('status', item);
      });
    } else {
      searchParams.delete('status');
    }

    if (yearsStatus === 'Все') {
      searchParams.delete('ys');
    } else {
      searchParams.set('ys', yearsStatus);
    }


    if (year) {
      searchParams.set('year', year);
    } else {
      searchParams.delete('year');
    }

    const filters = {
      ...(search && { personName: search }),
      ...(letter && { letter }),
      ...(statuses && { status: statuses }),
      ...(yearsStatus !== 'Все' && { yearsStatus }),
      ...(year && { year }),
    };

    dispatch(fetchPersonsThunk(0, +quantity, filters));
    dispatch(fetchPersonsLengthThunk(filters));
    setSearchParams(searchParams);
  }, [dispatch, letter, quantity, search, year, searchParams, setSearchParams, yearsStatus]);

  const reset = useCallback(() => {
    let tableQuantity = localStorage.getItem('table-quantity') || '5';

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setPage('0');
    searchParams.delete('page');

    setSearch('');
    searchParams.delete('search');

    setLetter('');
    searchParams.delete('letter');

    setStatus({});
    searchParams.delete('status');

    setYearsStatus('Все');
    searchParams.delete('ys');

    setYear('0');
    searchParams.delete('year');

    setSearchParams(searchParams);

    const filters = {};

    dispatch(fetchPersonsThunk(0, +tableQuantity, filters));
    dispatch(fetchPersonsLengthThunk(filters));
  }, [dispatch, searchParams, setSearchParams]);

  // EFFECTS
  useEffect(() => {
    document.title = "Алфавитный указатель";
  }, []); // eslint-disable-line

  useEffect(() => {   // Первая загрузка
    const search = searchParams.get('search');
    const letter = searchParams.get('letter');
    const statuses = searchParams.getAll('status');
    const yearsStatus = searchParams.get('ys');
    const year = searchParams.get('year');

    const filters = {
      ...(search && { personName: search }),
      ...(letter && { letter }),
      ...(statuses.length && { status: statuses }),
      ...(yearsStatus && { yearsStatus }),
      ...(year && { year }),
    };

    dispatch(fetchPersonsThunk(+quantity * +page, +quantity, filters));
    dispatch(fetchPersonsLengthThunk(filters));
    dispatch(fetchFacetsThunk());
  }, []); // eslint-disable-line

  useEffect(() => {   // Изменение страницы
    if (page !== prevPage) {
      const statuses = searchParams.getAll('status');
      const search = searchParams.get('search');
      const letter = searchParams.get('letter');
      const yearsStatus = searchParams.get('ys');
      const year = searchParams.get('year');

      const filters = {
        ...(search && { personName: search }),
        ...(letter && { letter }),
        ...(statuses.length && { status: statuses }),
        ...(yearsStatus && { yearsStatus }),
        ...(year && { year }),
      };

      dispatch(fetchPersonsThunk(+quantity * +page, +quantity, filters));
      dispatch(fetchPersonsLengthThunk(filters));
    }
  }, [page, prevPage, letter, dispatch, quantity, searchParams]);

  useEffect(() => {   // Изменение буквы
    if (letter !== prevLetter) {
      const statuses = searchParams.getAll('status');
      const search = searchParams.get('search');
      const yearsStatus = searchParams.get('ys');
      const year = searchParams.get('year');

      const filters = {
        ...(search && { personName: search }),
        ...(letter && { letter }),
        ...(statuses.length && { status: statuses }),
        ...(yearsStatus && { yearsStatus }),
        ...(year && { year }),
      };

      if (prevPage === page) {
        setPage('0');
        searchParams.delete('page');
        setSearchParams(searchParams);
      }

      dispatch(fetchPersonsThunk(+quantity * +page, +quantity, filters));
      dispatch(fetchPersonsLengthThunk(filters));
    }
  }, [page, letter, dispatch, quantity, searchParams, setSearchParams]); // eslint-disable-line
  /* [page, letter, dispatch, quantity, searchParams, setSearchParams, status] */

  useEffect(() => {
    if (facets.list && !status.done) {
      const status: { [key: string]: boolean } = {};

      const statuses = searchParams.getAll('status');

      facets.list.status.forEach(statusItem => {
        statusItem.list.forEach(regalia => {
          const currentRegalia = statuses.find(current => current === `${statusItem.query}/${regalia}`);

          if (currentRegalia) {
            status[`${statusItem.query}/${regalia}`] = true;
          } else {
            status[`${statusItem.query}/${regalia}`] = false;
          }
        });
      });

      status.done = true;
      setStatus(status);
    }

  }, [facets.list, status.done, searchParams]);

  return (
    <Container className={styles.container}>
      <h1 className={styles.title}>Алфавитный указатель</h1>
      {
        (persons.list && !persons.loading) && (facets.list && !facets.loading) &&
        <div className={styles.grid}>
          <Facet
            search={search}
            setSearch={setSearch}
            onSearch={searchHandler}
            resetSearch={resetSearch}
            loading={facets.loading}
            error={facets.error}
            list={facets.list}
            status={status}
            setStatus={setStatus}
            handleClick={facetsHandleClick}
            find={find}
            reset={reset}
            yearsStatus={yearsStatus}
            setYearsStatus={setYearsStatus}
            year={year}
            setYear={setYear}
          />
          <div className={styles['right-container']}>
            <Alphabet
              letter={letter}
              onClick={setLetterHandler}
            />
            <List
              list={persons.list}
              loading={persons.loading}
              error={persons.error}
              personsLength={persons.length}
              quantity={quantity}
              setQuantity={setQuanityHandler}
              page={page}
              pageChange={pageChangeHandler}
            />
          </div>
        </div>
      }
    </Container>
  );
}

export default AlphabeticalIndexPage;
