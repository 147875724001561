import { useEffect, useState, useRef, useCallback } from 'react';
import { useParams, Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../services/hooks/useStore';
import fetchPerson from '../../services/thunks/fetchPerson';
import fetchLiterature from '../../services/thunks/fetchLiterature';
import fetchRegalias from '../../services/thunks/fetchRegalias';
import { remove, personSelector } from '../../services/slices/person';
import { literatureSelector } from '../../services/slices/literature';
import { regaliasSelector } from '../../services/slices/regalias';
import { TLiteratue } from '../../types/literature';

import { Gallery, Popup } from './Components';
import { Container } from '../../Components';
import styles from './Person.module.css';
import useScroll from '../../services/hooks/useScroll';

export type TModal = {
  image: string;
  description: string;
};

type TRegalia = {
  year: any;
  title: string | undefined;
};

function PersonPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { person, loading, error } = useAppSelector(personSelector);
  const { list: literatureList, loading: literatureLoading } = useAppSelector(literatureSelector);
  const { list: regaliasList, loading: regaliasLoading } = useAppSelector(regaliasSelector);
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState<TModal | null>(null);
  const [literatureOfPerson, setLiteratureOfPerson] = useState<TLiteratue[]>([]);
  const [literatureAboutPerson, setLiteratureAboutPerson] = useState<TLiteratue[]>([]);
  const [personAdvInfo, setPersonAdvInfo] = useState<TLiteratue[]>([]);
  const [regalias, setRegalias] = useState<TRegalia[]>([]);
  const mainInfoRef = useRef<HTMLDivElement | null>(null);
  const favoriteRef = useRef<HTMLDivElement | null>(null);
  const literature = useRef<HTMLDivElement | null>(null);
  const additionally = useRef<HTMLDivElement | null>(null);
  const galleryRef = useRef<HTMLDivElement | null>(null);
  useScroll();

  useEffect(() => {
    if (!person && id) {
      dispatch(fetchPerson(id));
    }
  }, [person, dispatch, id]);

  useEffect(() => {
    if (!literatureList && !literatureLoading) {
      dispatch(fetchLiterature());
    }
  }, [literatureList, literatureLoading, dispatch]);

  useEffect(() => {
    if (!regaliasList && !regaliasLoading) {
      dispatch(fetchRegalias());
    }
  }, [regaliasList, regaliasLoading, dispatch]);

  useEffect(() => {
    if (literatureList && (!literatureOfPerson.length || !literatureAboutPerson.length || !personAdvInfo.length)) {
      if ((person && person.literatureOfPerson.length) && !literatureOfPerson.length) {
        const list: TLiteratue[] = [];

        person.literatureOfPerson.forEach(_id => {
          const literature = literatureList.find(literature => literature._id === _id);
          if (literature) {
            list.push(literature);
          }
        });

        setLiteratureOfPerson(list);
      }

      if ((person && person.literatureAboutPerson.length) && !literatureAboutPerson.length) {
        const list: TLiteratue[] = [];

        person.literatureAboutPerson.forEach(_id => {
          const literature = literatureList.find(literature => literature._id === _id);
          if (literature) {
            list.push(literature);
          }
        });

        setLiteratureAboutPerson(list);
      }

      if ((person && person.personAdvInfo.length) && !personAdvInfo.length) {
        const list: TLiteratue[] = [];

        person.personAdvInfo.forEach(_id => {
          const literature = literatureList.find(literature => literature._id === _id);
          if (literature) {
            list.push(literature);
          }
        });

        setPersonAdvInfo(list);
      }
    }
  }, [literatureList, literatureAboutPerson.length, literatureOfPerson.length, personAdvInfo.length, person]);

  useEffect(() => {
    if (person && (regaliasList && regaliasList.length) && !regalias.length) {
      const newRegalis = person.personScienceRegalia.map(item => ({
        year: item.yearGot,
        title: regaliasList.find(regalia => regalia._id === item.scienceRegalia)?.name,
      }));

      setRegalias(newRegalis);
    }

  }, [person, regaliasList, regalias.length]);

  useEffect(() => () => {
    dispatch(remove());
  }, [dispatch]);

  const handleNav = (ref: HTMLDivElement | null) => {
    if (ref) {
      window.scrollTo({
        top: ref.offsetTop - 110,
        behavior: "smooth"
      });
    }
  };

  const closeModal = useCallback(() => {
    setModal(null);
  }, []);

  const openModal = useCallback((image: string, description: string) => {
    setModal({
      image,
      description,
    });
  }, []);

  return (
    <>
      <Container>
        {
          person &&
          <section className={styles.breadcrumbs}>
            <span className={styles['breadcrumbs-gray']}>
              {
                location.state?.back ?
                  <span onClick={() => navigate(-1)}>
                    Алфавитный указатель
                  </span>
                  :
                  <Link to="/alphabetical-index" className={styles['breadcrumbs-gray']}>
                    Алфавитный указатель
                  </Link>
              }
            </span>
            <span>{'>'}</span>
            <span>
              {
                loading && 'Загрузка'
              }
              {
                person && `${person.name.last} ${person.name.first} ${person.name.middle}`
              }
            </span>
          </section>
        }
        {
          loading && <p className={styles.message}>Загрузка...</p>
        }
        {
          error && <p className={styles.message}>{error}</p>
        }
        {
          person &&
          <div className={styles.name__container}>
            <h1 className={styles.title}>
              {person.name.last} {person.name.first} {person.name.middle}
            </h1>
            <div className={styles.person_date_life}>
              {person.deathday !== '' ?
                <>{person.birthday} &mdash; {person.deathday}</>
                :
                <>род. {person.birthday}</>
              }
              {/* {person.birthday.split('-').join('.')} - {person.deathday.split('-').join('.')} */}
            </div>
          </div>
        }
        {
          person &&
          <div className={styles.container}>
            <section className={styles['left-container']}>
              <img
                src={person.imgHref ? `${process.env.REACT_APP_PHOTO_URL}/files/person/${person._id}/${person.imgHref}` : '/images/no-image.png'}
                alt={`${person.name.last} ${person.name.first} ${person.name.middle}`}
                className={styles.photo}
              />
              <nav className={styles.nav}>
                <h2 className={styles['nav-title']}>Навигация</h2>
                <ul className={styles['nav-list']}>
                  <li className={styles['nav-item']} onClick={() => handleNav(mainInfoRef.current)}><div />Основная информация</li>
                  {person.literatureOfPerson.length > 0 && <li className={styles['nav-item']} onClick={() => handleNav(favoriteRef.current)}><div />Избранные труды</li>}
                  {person.literatureAboutPerson.length > 0 && <li className={styles['nav-item']} onClick={() => handleNav(literature.current)}><div />Литература</li>}
                  {person.personAdvInfo.length > 0 && <li className={styles['nav-item']} onClick={() => handleNav(additionally.current)}><div />Дополнительные материалы</li>}
                  {person.gallery.length > 0 && <li className={styles['nav-item']} onClick={() => handleNav(galleryRef.current)}><div />Галерея</li>}
                </ul>
              </nav>
            </section>
            <section className={styles['right-container']} id="main-info" ref={mainInfoRef}>
              <p className={styles['regalia-text']}>
                <span className={styles['regalia-list']}>
                  {
                    regalias.map(
                      (item, i) =>
                        <span key={i} className={`${styles['regalia-list-item']}`}>
                          {i === 0 ? item.title : (item.title?.[0].toLowerCase() + (item.title || '').substring(1))}
                          {item.year !== 0 ? ` (${item.year})` : ''}
                          {i !== regalias.length - 1 ? ',' : '.'}
                        </span>
                    )
                  }
                </span>
                {person.personScienceRegaliaAdvText}
              </p>
              <p dangerouslySetInnerHTML={{ __html: person.mainText }} />
              {
                person.literatureOfPerson.length > 0 &&
                <article className={styles.chapter} ref={favoriteRef}>
                  <h2 className={styles['text-title']}>Избранные труды</h2>
                  <ul className={styles['nav-list']}>
                    {
                      literatureOfPerson.map(
                        item =>
                          <li key={item._id} className={styles['nav-item'] + ' ' + styles['nav-item_no_pointer']}>
                            <div />
                            {
                              (item.fullTextLink || item.pdfVersionPath) &&
                              <a
                                href={item.fullTextLink || `${process.env.REACT_APP_PHOTO_URL}/files/literature/` + item.pdfVersionPath}
                                target="_blank"
                                rel="noreferrer"
                                className={styles.link}
                              >
                                {item.title}
                              </a>
                            }
                            {
                              (!item.fullTextLink && !item.pdfVersionPath) &&
                              <span className={styles.link}>
                                {item.title}
                              </span>
                            }
                          </li>
                      )
                    }
                  </ul>
                </article>
              }
              {
                person.literatureAboutPerson.length > 0 &&
                <article className={styles.chapter} ref={literature}>
                  <h2 className={styles['text-title']}>Литература о жизни и деятельности</h2>
                  <ul className={styles['nav-list']}>
                    {
                      literatureAboutPerson.map(
                        item =>
                          <li key={item._id} className={styles['nav-item'] + ' ' + styles['nav-item_no_pointer']}>
                            <div />
                            {
                              (item.fullTextLink || item.pdfVersionPath) &&
                              <a
                                href={item.fullTextLink || `${process.env.REACT_APP_PHOTO_URL}/files/literature/` + item.pdfVersionPath}
                                target="_blank"
                                rel="noreferrer"
                                className={styles.link}
                              >
                                {item.title}
                              </a>
                            }
                            {
                              (!item.fullTextLink && !item.pdfVersionPath) &&
                              <span className={styles.link}>
                                {item.title}
                              </span>
                            }
                          </li>
                      )
                    }
                  </ul>
                </article>
              }
              {
                person.personAdvInfo.length > 0 &&
                <article className={styles.chapter} ref={additionally}>
                  <h2 className={styles['text-title']}>Дополнительные материалы</h2>
                  <ul className={styles['nav-list']}>
                    {
                      personAdvInfo.map(
                        item =>
                          <li key={item._id} className={styles['nav-item'] + ' ' + styles['nav-item_no_pointer']}>
                            <div />

                            {
                              (item.fullTextLink || item.pdfVersionPath) &&
                              <a
                                href={item.fullTextLink || `${process.env.REACT_APP_PHOTO_URL}/files/literature/` + item.pdfVersionPath}
                                target="_blank"
                                rel="noreferrer"
                                className={styles.link}
                              >
                                {item.title}
                              </a>
                            }
                            {
                              (!item.fullTextLink && !item.pdfVersionPath) &&
                              <span className={styles.link}>
                                {item.title}
                              </span>
                            }
                          </li>
                      )
                    }
                  </ul>
                </article>
              }
              {
                person.gallery.length > 0 &&
                <Gallery
                  id={person._id}
                  gallery={person.gallery}
                  open={openModal}
                  galleryRef={galleryRef}
                />
              }
            </section>
          </div>
        }
      </Container>
      {
        modal && <Popup close={closeModal} image={modal.image} description={modal.description} />
      }
    </>
  );
}

export default PersonPage;
