import Container from '../Container/Container';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import styles from './Footer.module.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className={styles.footer}>
      <Container className={styles.container}>
        <section className={styles['info-container']}>
          <div className={styles['buttons-container']}>
            <Button className={styles.button}>
              <Icon type="ran" />
            </Button>
            <Button className={styles.button}>
              <Icon type="gpntb" />
            </Button>
          </div>
          <p className={styles.text}>
            Комплексный информационный ресурс к 300-летию РАН
          </p>
          <p className={styles.copyright}>
            © 2023 ГПНТБ СО РАН
          </p>
        </section>
        <nav className={styles['nav']}>
          <div className={styles['nav-block']}>
            <h3>
              Навигация
            </h3>
            <Link to="/alphabetical-index">Алфавитный указатель</Link>
            <Link to="/active-members">Действительные члены</Link>
            <Link to="/corresponding-members">Члены-корреспонденты</Link>
            <Link to="/about">О проекте</Link>
          </div>
          <div className={styles['nav-block']}>
            <h3>
              Ресурсы о науке ГПНТБ СО РАН
            </h3>
            <Link to="http://www.prometeus.nsc.ru/science/calendar/" target="_blank">Календарь памятных дат СО РАН</Link>
            <Link to="http://www.prometeus.nsc.ru/science/schools/" target="_blank">Научные школы ННЦ</Link>
            <Link to="http://prometeus.nsc.ru/about/memlib.ssi">Мемориальные библиотеки</Link>
          </div>
          <div className={styles['nav-block']}>
            <h3>
              Полезные ссылки
            </h3>
            <Link to="http://www.spsl.nsc.ru/">ГПНТБ СО РАН</Link>
            <Link to="https://www.sbras.ru/">СО РАН</Link>
            <Link to="https://www.ras.ru/">РАН</Link>
            <Link to="https://www.sbras.info/main">Наука в Сибири</Link>
          </div>
        </nav>
      </Container>
    </footer>
  );
}

export default Footer;
