import { Link } from 'react-router-dom';
// import Search from '../Search/Search';
import styles from './Nav.module.css';

interface INavProps {
  onClick: () => void;
}

function Nav(props: INavProps) {
  const { onClick } = props;

  return (
    <nav className={styles.container}>
      <div className={styles.overlay}></div>
      <div className={styles.nav}>
        {/* <h2 className={styles.title}>Поиск</h2> */}
        {/* <Search /> */}
        <h2 className={styles.title}>Навигация</h2>
        <Link to="/" className={styles.link} onClick={onClick}>
          Главная страница
        </Link>
        <Link to="/alphabetical-index" className={styles.link} onClick={onClick} reloadDocument>
          Алфавитный указатель
        </Link>
        <Link to="/alphabetical-index?ys=Действительный+член" className={styles.link} onClick={onClick} reloadDocument>
          Действительные члены
        </Link>
        <Link to="/alphabetical-index?ys=Член-корреспондент" className={styles.link} onClick={onClick} reloadDocument>
          Члены-корреспонденты
        </Link>

        <Link to="/about" className={styles.link} onClick={onClick}>
          О проекте
        </Link>
        <h2 className={styles.title}>Ресурсы о науке гпнтб со ран</h2>
        <Link to="http://www.prometeus.nsc.ru/science/calendar/" className={styles.link}>
          Календарь памятных дат СО РАН
        </Link>
        <Link to="http://www.prometeus.nsc.ru/science/schools/" className={styles.link}>
          Научные школы ННЦ
        </Link>
        <Link to="http://prometeus.nsc.ru/about/memlib.ssi" className={styles.link}>
          Мемориальные библиотеки
        </Link>
        <h2 className={styles.title}>Полезные ссылки</h2>
        <Link to="http://www.spsl.nsc.ru/" className={styles.link}>
          ГПНТБ СО РАН
        </Link>
        <Link to="https://www.sbras.ru/" className={styles.link}>
          СО РАН
        </Link>
        <Link to="https://www.ras.ru/" className={styles.link}>
          РАН
        </Link>
        <Link to="https://www.sbras.info/main" className={styles.link}>
          Наука в Сибири
        </Link>
      </div>
    </nav>
  );
}

export default Nav;
