import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TRootState } from "../../store";
import {TPersonLetter} from "../../types/personLetter";

interface IPersonsLettersState {
  list: TPersonLetter[] | null;
  loading: boolean;
  error: string;
}

const initialState: IPersonsLettersState = {
  list: null,
  loading: false,
  error: '',
};

export const personsLettersSlice = createSlice({
  name: 'letters',
  initialState,
  reducers: {
    fetch: (state) => {
      state.error = '';
      state.loading = true;
    },
    success: (state, action: PayloadAction<TPersonLetter[]>) => {
      state.loading = false;
      state.list = action.payload;
    },
    fail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetch, success, fail } = personsLettersSlice.actions;

export const personsLettersSelector = (state: TRootState) => state.letters;

export default personsLettersSlice.reducer;
