import { useEffect } from 'react';

import { Container } from '../../Components';
import useScroll from '../../services/hooks/useScroll';
import styles from './About.module.css';

function AboutPage() {
  useEffect(() => {
    document.title = "О проекте";
  }, []);

  useScroll();
  
  return (
    <Container>
      <h1 className={styles.title}>О проекте</h1>
      <div className={styles.container}>
        <section className={styles.about}>
          <p className={styles.text}>
            В 2024 году Российская академия наук отмечает свое 300-летие. Большой вклад в развитие отечественной науки внесло Сибирское отделение РАН, созданное в 1957 году. Во главе фундаментальных научных исследований стояли крупнейшие ученые, находящиеся в статусе действительных членов академии РАН и членов-корреспондентов. Электронные ресурсы, содержащие сведения о жизни и научной деятельности таких ученых, разрознены и только некоторые из этих исследователей имеют персональные сайты.
          </p>
          <p className={styles.text}>
            ГПНТБ СО РАН подготовила новый проект – комплексный информационный ресурс, объединяющий на единой платформе фактографическую и библиографическую информацию о действительных членах РАН и членах-корреспондентах, работавших в Сибирском отделении РАН (включая бывшие сибирские отделения РАСХН и РАМН, объединенных с РАН в 2013 году).
          </p>
          <p className={styles.text}>
            Проект создан в целях пропаганды научных достижений, истории отдельных научных дисциплин и сохранения для последующих поколений образов выдающихся ученых.
          </p>
          <p className={styles.text}>
            Ресурс представлен персональными страницами ученых, снабжен возможностью сортировки по алфавиту, годам избрания в действительные члены и члены-корреспонденты академии наук. Каждая персональная страница содержит:
            <ul>
              <li>
                фотографии (фотопортрет и при наличии фоторяд до трех изображений);
              </li>
              <li>
                очерк о жизни и деятельности ученого;
              </li>
              <li>
                библиографический указатель наиболее важных научных трудов (в количестве от пяти до десяти);
              </li>
              <li>
                литературу об ученом (около пяти публикаций);
              </li>
              <li>
                выборочно представлены полные тексты некоторых работ.
              </li>
            </ul>
          </p>
          <p className={styles.text}>
            Мы будем благодарны за предложения и замечания, которые позволят улучшить форму и содержание нашего ресурса. Наши контакты: branch@gpntbsib.ru.
          </p>
        </section>
        <section className={styles['image-container']}>
          <img src="/images/about-image.png" alt="О проекте" className={styles.image} />
        </section>
      </div>
    </Container>
  );
}

export default AboutPage;
