import stylesRegalia from './RegaliaListOut.module.css';
import stylesList from '../../../AlphabeticalIndex/Components/List/List.module.css';
import styles from '../../../Person/Person.module.css';
import {TPerson} from "../../../../types/persons";

interface IRegaliaListOutProps {
  person: TPerson;
}
const RegaliaListOut = (props: IRegaliaListOutProps) => {
  const {
    person
  } = props;

  if (!person || !person.personScienceRegalia) {
    return null;
  }

  let resultList = person.personScienceRegalia.map(
    (regalia: any, i: any) => {
      let regaliaName: string;
      let result = "";
      regaliaName = regalia.scienceRegalia.name;
      result += (i === 0 ? regaliaName : (regaliaName?.[0].toLowerCase() + (regaliaName || '').substring(1)));
      result += (regalia.yearGot !== 0 ? ` (${regalia.yearGot})` : '');
      result += (i !== person.personScienceRegalia.length - 1 ? ', ' : '. ');
      return result;
    }
  );
  return (
    <p className={stylesRegalia['regalia-list-text']}>
      <div className={stylesList['regalias']}>
        {
          resultList.map(
            (regalia: string, i: number) =>
              <span key={i} className={`${styles['regalia-list-item']}`}>
                {regalia}
              </span>
          )
        }
      </div>
      <div className={stylesList['description']}>
        {person.personScienceRegaliaAdvText}
      </div>
    </p>
  )
}

export default RegaliaListOut;