import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TRootState } from "../../store";
import { TPerson } from '../../types/persons';

interface IPersonsState {
  person: null | TPerson;
  loading: boolean;
  error: string;
}

const initialState: IPersonsState = {
  person: null,
  loading: false,
  error: '',
};

export const personSlice = createSlice({
  name: 'person',
  initialState,
  reducers: {
    fetch: (state) => {
      state.error = '';
      state.loading = true;
    },
    success: (state, action: PayloadAction<TPerson[]>) => {
      state.loading = false;
      state.person = action.payload[0];
    },
    fail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    remove: (state) => {
      state.person = null;
    }
  },
});

export const { fetch, success, fail, remove } = personSlice.actions;

export const personSelector = (state: TRootState) => state.person;

export default personSlice.reducer;
