import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TRootState } from "../../store";
import { TPerson } from '../../types/persons';

interface IPersonsState {
  list: null | TPerson[];
  loading: boolean;
  error: string;
  length: number;
}

const initialState: IPersonsState = {
  list: null,
  loading: false,
  error: '',
  length: 0,
};

export const personsSlice = createSlice({
  name: 'persons',
  initialState,
  reducers: {
    fetch: (state) => {
      state.error = '';
      state.loading = true;
    },
    success: (state, action: PayloadAction<TPerson[]>) => {
      state.loading = false;
      state.list = action.payload;
    },
    fail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    setPersonsLength: (state, action: PayloadAction<number>) => {
      state.length = action.payload;
    },
  },
});

export const { fetch, success, fail, setPersonsLength } = personsSlice.actions;

export const personsSelector = (state: TRootState) => state.persons;

export default personsSlice.reducer;
