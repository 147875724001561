import styles from './Button.module.css';

type TButtonColors = 'white' | 'primary' | 'black';

interface IButtonProps {
  children: string | JSX.Element | JSX.Element[];
  className?: string;
  color?: TButtonColors;
  onClick?: () => void;
}

function Button(props: IButtonProps) {
  const { children, className, color, onClick } = props;

  const classColor = color ? styles[color] : styles.white;

  return (
    <button
      className={`${styles.button} ${className} ${classColor}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default Button;
