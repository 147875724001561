import React from 'react';

function usePrev<TValue>(value: TValue): TValue | undefined {
  const ref = React.useRef<TValue>();

  React.useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

export default usePrev;
