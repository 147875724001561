import { Routes, Route } from 'react-router-dom';

import HomePage from '../../Pages/Home/Home';
import AlphabeticalIndexPage from '../../Pages/AlphabeticalIndex/AlphabeticalIndex';
import AboutPage from '../../Pages/About/About';
import PersonPage from '../../Pages/Person/Person';

function Router() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/alphabetical-index" element={<AlphabeticalIndexPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/person/:id" element={<PersonPage />} />
    </Routes>
  );
}

export default Router;
