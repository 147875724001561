import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../services/hooks/useStore';
import fetchPersons from '../../../../services/thunks/fetchRandomPerson';

import { Link } from 'react-router-dom';
import { Button, Loader } from '../../../../Components';
import styles from './Persons.module.css';
import fetchRegalias from "../../../../services/thunks/fetchRegalias";
import { regaliasSelector } from "../../../../services/slices/regalias";
import RegaliaListOut from "../RegaliaListOut/RegaliaListOut"

function Persons() {
  const dispatch = useAppDispatch();

  const persons = useAppSelector(store => store.persons);
  const { list: regaliasList, loading: regaliasLoading } = useAppSelector(regaliasSelector);

  useEffect(() => {
    dispatch(fetchPersons(6));
  }, [dispatch]);

  useEffect(() => {
    if (!regaliasList && !regaliasLoading) {
      dispatch(fetchRegalias());
    }
  }, [regaliasList, regaliasLoading, dispatch]);

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        Лица сибирской науки
      </h2>
      <section className={styles.list}>
        {
          persons.loading &&
          <div className={styles['loader-container']}>
            <Loader />
          </div>
        }
        {
          persons.error &&
          <p>{persons.error}</p>
        }

        {
          persons.list &&
          persons.list.map(
            (item, i) =>
              <Link key={i} to={`/person/${item._id}`} className={styles['card-link']}>
                <article className={styles.card}>
                  <img
                    src={item.imgHref ? `${process.env.REACT_APP_PHOTO_URL}/files/person/${item._id}/${item.imgHref}` : '/images/no-image.png'}
                    alt={`${item.name.last} ${item.name.first} ${item.name.middle}`}
                  />
                  <div className={styles['inner-container']}>
                    <h1 className={styles['card-title']}>
                      {`${item.name.last} ${item.name.first} ${item.name.middle}`}
                    </h1>

                    <RegaliaListOut person={item} ></RegaliaListOut>

                  </div>
                </article>
              </Link>
          )
        }
      </section>
      {
        !persons.loading &&
        <Link to="/alphabetical-index" className={styles.button}>
          <Button color="primary">Показать еще</Button>
        </Link>
      }
    </div>
  );
}

export default Persons;
