import { Link } from 'react-router-dom';
import styles from './Resources.module.css';


const data = [
  { title: 'Календарь памятных дат СО РАН', description: 'В Календаре отражаются наиболее значительные события из истории Сибирского отделения РАН, в него включены даты создания научно-исследовательских институтов СО РАН; юбилеи ключевых фигур сибирской науки — директоров научно-исследовательских учреждений, руководителей научных школ, академиков и членов-корреспондентов Российской академии наук; крупные события общественной и культурной жизни Новосибирского научного центра; профессиональные праздники, соответствующие направлениям научной деятельности институтов Сибирского отделения.', link: 'http://www.prometeus.nsc.ru/science/calendar/' },
  { title: 'Научные школы ННЦ', description: 'В Отделении ГПНТБ СО РАН с 2000 г. ведется работа по созданию электронных биобиблиографических ресурсов выдающихся ученых СО РАН. Каждый персональный ресурс состоит из: краткой справки о научном направлении ученого, его биографии, указателя трудов в хронологическом порядке, литературы об ученом, полных текстов некоторых работ лидера школы и т.д. В проекте представлены школы по механико-математическим, физическим, химическим, гуманитарным наукам и науке о Земле. ', link: 'http://www.prometeus.nsc.ru/science/schools/' },
  { title: 'Мемориальные библиотеки', description: 'В Отделении ГПНТБ СО РАН организованы мемориальные библиотеки известных ученых Новосибирского научного центра — академиков Валентина Афанасьевича Коптюга, Льва Васильевича Овсянникова и Николая Николаевича Яненко. Помимо архивных материалов, документов, рукописей, книг и журналов, среди экспонатов представлены переданные в дар библиотеке фотографии, сувениры, награды, личные вещи и предметы интерьера, что позволило воссоздать обстановку кабинетов ученых.', link: 'http://prometeus.nsc.ru/about/memlib.ssi' },
];

function Resources() {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        Ресурсы
      </h2>
      <div className={styles.list}>
        {
          data.map(
            (item, i) =>
              <Link key={i} to={item.link} className={styles['card-link']} target="_blank">
                <article className={styles.card}>
                  <h1 className={styles['card-title']}>
                    {item.title}
                  </h1>
                  <p className={styles.description}>
                    {item.description}
                  </p>
                </article>
              </Link>
          )
        }
      </div>
    </div>
  );
}

export default Resources;
