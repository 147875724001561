import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TRootState } from "../../store";
import { TFacets } from '../../types/facets';

interface IFacetsState {
  list: null | TFacets;
  loading: boolean;
  error: string;
}

const initialState: IFacetsState = {
  list: null,
  loading: false,
  error: "",
};

export const facetsSlice = createSlice({
  name: "facets",
  initialState: initialState,
  reducers: {
    fetch: (state) => {
      state.error = "";
      state.loading = true;
    },
    success: (state, action: PayloadAction<TFacets>) => {
      state.loading = false;
      state.list = action.payload;
    },
    fail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetch, success, fail } = facetsSlice.actions;

export const facetSelector = (state: TRootState) => state.facets;

export default facetsSlice.reducer;
