import { Icon } from '../../../../Components';
import styles from './Popup.module.css';

interface IPopupProps {
  close: () => void;
  image: string;
  description: string;
}

function Popup(props: IPopupProps) {
  const { close, image, description } = props;

  return (
    <div className={styles.overlay}>
      <button className={styles['close-button']} onClick={close}>
        <Icon type="gray-close" className={styles['close-icon']} />
      </button>
      <figure className={styles['image-container']}>
        <img src={image} alt={description} className={styles.image} />
        <figcaption className={styles.description}>
          {description}
        </figcaption>
      </figure>
    </div>
  );
}

export default Popup;
