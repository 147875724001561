import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { TRootState } from '../../store';
import LiteratureApi from '../../API/LiteratureApi';
import { fetch, success, fail } from '../slices/literature';

function fetchLiterature(): ThunkAction<void, TRootState, unknown, AnyAction> {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const result = await LiteratureApi.fetchLiterature();

      if (result.status === 200) {
        dispatch(success(result.data));
      } else {
        dispatch(fail('Ошибка загрузки литературы'));
      }
    } catch(error) {
      dispatch(fail('Ошибка загрузки литературы'));
    }
  };
}

export default fetchLiterature;
