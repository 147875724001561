import { memo } from 'react';
import { letters } from './letters';

import {useEffect} from 'react';
import { useAppDispatch, useAppSelector } from '../../../../services/hooks/useStore';
import fetchPersonsLetters from '../../../../services/thunks/fetchPersonsLetters';
import {personsLettersSelector} from "../../../../services/slices/personsLetters";

import styles from './Alphabet.module.css';

interface IAlphabetProps {
  letter: string;
  onClick: (value: string) => void;
}

function Alphabet(props: IAlphabetProps) {
  const dispatch = useAppDispatch();

  const { list: personsLettersList } = useAppSelector(personsLettersSelector);

  useEffect(() => {
    dispatch(fetchPersonsLetters());
  }, [dispatch]);

  const { letter, onClick } = props;

  if (personsLettersList == null) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.letters}>
        {
          personsLettersList.map(
            item =>
              <span
                key={item._id}
                className={`${styles.letter} ${letter === item._id && styles.active}`}
                onClick={() => onClick(item._id)}
              >
                {item._id}
              </span>
          )
          // letters.map(
          //   item =>
          //     <span
          //       key={item}
          //       className={`${styles.letter} ${letter === item && styles.active}`}
          //       onClick={() => onClick(item)}
          //     >
          //       {item}
          //     </span>
          // )
        }
      </div>
    </div>
  );
}

export default memo(Alphabet);
