import { MutableRefObject } from 'react';

import { Icon } from '../../../../Components';
import styles from './Gallery.module.css';

interface IGalleryProps {
  id: string;
  gallery: {
    id: string;
    href: string;
    text: string;
  }[];
  open: (image: string, description: string) => void;
  galleryRef: MutableRefObject<HTMLDivElement | null>;
}

function Gallery(props: IGalleryProps) {
  const { id, gallery, open, galleryRef } = props;

  return (
    <section className={styles.container} ref={galleryRef}>
      <h2 className={styles.title}>Галерея</h2>
      <div className={styles.list}>
        {
          gallery.map(
            item =>
              <figure key={item.id} className={styles.figure}>
                <div className={styles['image-container']}>
                  <img
                    className={styles.image}
                    alt={item.text}
                    src={`${process.env.REACT_APP_PHOTO_URL}/files/person/${id}/${item.href}`}
                  />
                  <button className={styles.button} onClick={() => open(`${process.env.REACT_APP_PHOTO_URL}/files/person/${id}/${item.href}`, item.text)}>
                    <Icon type="square" />
                  </button>
                </div>
                <figcaption className={styles.text}>
                  {item.text}
                </figcaption>
              </figure>
          )
        }
      </div>
    </section>
  );
}

export default Gallery;
