import { memo, useCallback } from 'react';
import useToggle from '../../../../services/hooks/useToggle';
import { TFacets } from '../../../../types/facets';

import { Button, Checkbox, Loader, RadioButtons } from '../../../../Components';
import Search from '../Search/Search';
import styles from './Facet.module.css';

interface IFacetProps {
  search: string;
  setSearch: (value: string) => void;
  onSearch: () => void;
  resetSearch: () => void;
  error: string;
  loading: boolean;
  list: TFacets | null;
  status: {
    [key: string]: boolean;
  },
  setStatus: (value: {
    [key: string]: boolean;
  }) => void;
  handleClick: (name: string, value: boolean) => void;
  find: (statuses: string[]) => void;
  reset: () => void;
  yearsStatus: string;
  setYearsStatus: (value: string) => void;
  year: string;
  setYear: (value: string) => void;
}

function Facet(props: IFacetProps) {
  const {
    search, setSearch, onSearch, resetSearch,
    error, loading, list, status, /* setStatus, */ handleClick,
    find, reset, yearsStatus, setYearsStatus, year, setYear,
  } = props;

  const [isOpen, setOpen] = useToggle(false);

  const resetFacets = useCallback(() => {
    setOpen(false);
    // setStatus({});
    // setYearsStatus('Все');
    // setYear('');
    reset();
  }, [reset, setOpen]);

  const handleFindClick = () => {
    const statuses = [];
    for (let key in status) {
      if (status[key] && key !== 'done') {
        statuses.push(key);
      }
    }

    find(statuses);
  };

  const handleRadio = useCallback((value: string) => {
    setYearsStatus(value);
  }, [setYearsStatus]);

  return (
    <section className={styles.container}>
      <Search
        value={search}
        setSearch={setSearch}
        onSearch={onSearch}
        reset={resetSearch}
      />
      <Button
        className={styles.button}
        color="primary"
        onClick={() => setOpen()}
      >
        Расширенный поиск
      </Button>
      <div className={`${!isOpen && styles.hide}`}>
        {error && <p>{error}</p>}
        {
          loading &&
          <div className={styles['loader-container']}>
            <Loader className={styles.loader} />
          </div>
        }
        {
          (!error && !loading && list && status.done) &&
          <>
            <h3 className={styles.title}>Учёное звание и Годы избрания</h3>
            {
              list.status.map(
                (statusItem, i) =>
                  <article
                    className={styles.filters}
                    key={i}
                  >
                    <h1 className={styles['sub-title']}>{statusItem.title}</h1>
                    {
                      statusItem.list.map(
                        (item, index) => (
                          <Checkbox
                            key={index}
                            name={`${statusItem.query}/${item}`}
                            className={styles.checkbox}
                            value={status[`${statusItem.query}/${item}`]}
                            onClick={handleClick}
                            label={item}
                          />
                        )
                      )
                    }
                  </article>
              )
            }
            <h3 className={styles.title}>Выборочно по годам:</h3>
            <article className={styles.filters}>
              <RadioButtons className={styles['radio-buttons']}>
                {
                  list.changes.status.map(
                    (item, i) =>
                      <RadioButtons.Button
                        key={i}
                        className={styles['radio-button']}
                        label={item}
                        active={yearsStatus === item}
                        onClick={handleRadio}
                      />
                  )
                }
              </RadioButtons>
            </article>
            <article className={styles.filters}>
              <h1 className={styles['sub-title']}>Годы:</h1>
              <div className={styles['years-grid']}>
                {
                  list.changes.years
                    .filter(el => el !== 0)
                    .sort()
                    .map(
                      item =>
                        <span
                          key={item}
                          className={`${styles.year} ${year === `${item}` && styles.active}`}
                          onClick={() => setYear(`${item}`)}
                        >
                          {item}
                        </span>
                    )
                }
              </div>
            </article>
          </>
        }
      </div>

      <div className={styles.buttons}>
        <Button color="primary" onClick={() => handleFindClick()}>
          Применить
        </Button>
        <Button className={styles.clear} onClick={() => resetFacets()}>
          Сбросить
        </Button>
      </div>
    </section>
  );
}

export default memo(Facet);