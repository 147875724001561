import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { TRootState } from '../../store';
import RegaliasApi from '../../API/RegaliasApi';
import { fetch, success, fail } from '../slices/regalias';

function fetchRegalias(): ThunkAction<void, TRootState, unknown, AnyAction> {
  return async (dispatch) => {
    dispatch(fetch());

    try {
      const result = await RegaliasApi.fetchRegalias();

      if (result.status === 200) {
        dispatch(success(result.data));
      } else {
        dispatch(fail('Ошибка загрузки списка регалий'));
      }
    } catch(error) {
      dispatch(fail('Ошибка загрузки списка регалий'));
    }
  };
}

export default fetchRegalias;
