import styles from './Container.module.css';

interface IContainerProps {
  children: any;
  className?: string | string[];
}

function Container(props: IContainerProps) {
  const { children, className } = props;
  
  return (
    <div className={`${styles.container} ${className}`}>
      {children}
    </div>
  );
}

export default Container;
